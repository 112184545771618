const audioPropertiesArr = [{
  defaultValue: 0.5,
  title: 'Room Background'
}, {
  defaultValue: 0.5,
  title: 'Coffee Machine'
}, {
  defaultValue: 0.5,
  title: 'People Talking'
}, {
  defaultValue: 0.3,
  title: 'Printer Noise'
}, {
  defaultValue: 0.4,
  title: 'Noise from window'
}, {
  defaultValue: 0.5,
  title: 'Telephone'
}, {
  defaultValue: 0,
  title: 'Office Dog'
}, {
  defaultValue: 0.5,
  title: 'Keyboard'
}, {
  defaultValue: 0.2,
  title: 'Rain on window'
}]

export default audioPropertiesArr
