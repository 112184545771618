import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import BufferedLoader from './../lib/BufferLoader'
import AudioPlayer from './../lib/AudioPlayer'

import AudioFilePaths from './../constants/audio.urls'
import AudioPropertiesArr from './../constants/audio.properties'

import Pause from './../images/pause.svg'
import Play from './../images/play.svg'

let AudioContext

if (typeof window !== 'undefined') {
  AudioContext = window.AudioContext || window.webkitAudioContext
}

class IndexPage extends React.PureComponent {
  state = {
    audioLoaded: false,
    playing: false,
    showOverlay: true,
  }

  context = typeof window !== 'undefined' && new AudioContext()
  bufferList = []
  audioList = []

  componentDidMount() {
    new BufferedLoader(new (window.AudioContext || window.webkitAudioContext), AudioFilePaths, this.initializeApp).load()
  }

  initializeApp = (bufferList) => {
    this.setState({
      audioLoaded: true,
    }, () => {
      this.bufferList = bufferList
      this.audioList = [
        new AudioPlayer(0.5, bufferList[0]),
        new AudioPlayer(0.5, bufferList[1]),
        new AudioPlayer(0.5, bufferList[2]),
        new AudioPlayer(0.3, bufferList[3]),
        new AudioPlayer(0.4, bufferList[4]),
        new AudioPlayer(0.5, bufferList[5]),
        new AudioPlayer(0, bufferList[6]),
        new AudioPlayer(0.5, bufferList[7]),
        new AudioPlayer(0.2, bufferList[8]),
      ]
    })
  }

  hideOverlayAndPlayAll = () => {
    this.setState({
      showOverlay: false
    })

    this.togglePlay()
  }

  togglePlay = () => {
    this.setState((state) => ({
      playing: !state.playing,
    }))

    for (const audio of this.audioList) {
      audio.toggle()
    }
  }
  
  render() {
    const { audioLoaded, playing, showOverlay } = this.state
    const audioList = this.audioList

    return [<SEO title="Home" />,
      !showOverlay && (
        <Layout key="main-layout">
          <div style={{ zIndex: '-1', position: 'absolute', minWidth: '65%', overflow: 'hidden', transform: 'translateY(-50%) translateX(-50%)', top: '50%', left: '50%' }}>
            <Image />
          </div>
          <h1>Hi people</h1>
          <p>Welcome to your virtual office. Don&apos;t miss the office noise while working from home!</p>
          {
            !showOverlay && (
              <div className="audio-container">
                {
                  AudioPropertiesArr.map(({ defaultValue, title }, index) => (
                    <div className="audio-player" key={title}>
                      <span className="title">{title}</span>
                      <div className="audio__controls">
                        <div className="audio__controls__item audio__volume">
                          <input type="range" step="0.05" min="0" max="1" defaultValue={defaultValue} onInput={audioList[index].changeVolume} />
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            )
          }
        </Layout>
      ), showOverlay ? (
        <div style={{ zIndex: '5', position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#0a0a0afc', top: '0', color: '#fcfcfc', padding: '16px'}}  key="overlay">
          <div>
            <h1>Do you miss your office as much as I do?</h1>
            <h1>I miss it so much thats why created this app so that I have some accompany!</h1>
            <br/>
          </div>
          <div>
            {
              audioLoaded ? <button onClick={this.hideOverlayAndPlayAll}>Enter Office</button> : 'Loading your favorite sounds'
            }
          </div>
        </div>
      ) : null,
      !showOverlay ? (
        <div style={{position: 'fixed', top: '30px', right: '50px', zIndex: '10'}} onClick={this.togglePlay}>
          <img src={playing ? Pause : Play} alt="play" height="30"/>
        </div>
      ) : null,
    ]
  }
}

export default IndexPage
