const audioPathArr = [
  './../audio/ROOMTONE.mp3',
  './../audio/COFFEE.mp3',
  './../audio/PEOPLE.mp3',
  './../audio/PRINTER.mp3',
  './../audio/OPEN_WINDOW.mp3',
  './../audio/TELEPHONE.mp3',
  './../audio/THE_OFFICE_DOG.mp3',
  './../audio/KEYBOARD.mp3',
  './../audio/RAIN_ON_WINDOW.mp3',
]

export default audioPathArr
