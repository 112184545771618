let AudioContext

if (typeof window !== 'undefined') {
  AudioContext = window.AudioContext || window.webkitAudioContext
}

class AudioPlayer {
  constructor(initialVolume, buffer) {
    this.playing = false
    this.gainNode = null
    this.context = typeof window !== 'undefined' && new AudioContext()
    this.initialVolume = initialVolume
    this.buffer = buffer
  }

  play = () => {
    if (!this.context.createGain) {
      this.context.createGain = this.context.createGainNode
    }
    this.gainNode = this.context.createGain()
    const t = this.context.createBufferSource();
    t.buffer = this.buffer
    t.connect(this.gainNode)
    this.gainNode.connect(this.context.destination)
    this.gainNode.gain.value = this.initialVolume
    t.loop = true
    if (!t.start) {
      t.start = t.noteOn
    }
    t.start(0)
    this.source = t
  }

  changeVolume = (element) => {
    const t = element.target
    var e = t.value;
    parseInt(t.value)
    parseInt(t.max)
    this.gainNode.gain.value = e
  }

  stop = () => {
    if (!this.source.stop) {
      this.source.stop = this.source.noteOff
    }
    this.source.stop(0);
  }

  toggle = () => {
    this.playing ? this.stop() : this.play()
    this.playing = !this.playing
  }
}

export default AudioPlayer
